<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="4"
      >
        <router-link to="/discounts/promocodes">
          <b-card class="text-center cursor-pointer">
            <div>
              <h2 class="my-5 font-weight-bolder">
                Promocodes
              </h2>
            </div>
          </b-card>
        </router-link>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <router-link to="/discounts/multidevices">
          <b-card class="text-center cursor-pointer">
            <div>
              <h2 class="my-5 font-weight-bolder">
                Multi Device
              </h2>
            </div>
          </b-card>
        </router-link>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <router-link to="/discounts/multiitems">
          <b-card class="text-center cursor-pointer">
            <div>
              <h2 class="my-5 font-weight-bolder">
                Multi Item
              </h2>
            </div>
          </b-card>
        </router-link>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <router-link to="/discounts/pricefactors">
          <b-card class="text-center cursor-pointer">
            <div>
              <h2 class="my-5 font-weight-bolder">
                Price Factor
              </h2>
            </div>
          </b-card>
        </router-link>
      </b-col>
    </b-row>

  </div>
</template>

<script>
export default {

}
</script>
